<template>
	<div>
		<h2>{{ $t('tiers.menu_parametres') }}</h2>
		<template v-if="loaded">
			<div class="box">
				<h3>{{ $t('tiers.parametres.association_modele_courrier') }}</h3>

				<template>
					<div
						v-for="accounting_plan in accounting_plans"
						:key="accounting_plan.accountingplan_id"
					>
						<label>{{ accounting_plan.accountingplan_label }}</label>
						<p
							v-for="model in accounting_plan.models"
							:key="model.model_id"
						>
							<b-form-checkbox
								v-model="model.default"
								:unchecked-value="false"
								:checked-value="true"
								@change="toggle(accounting_plan.accountingplan_id, model.model_id)"
							>
							{{ model.model_label }} ({{ model.type.modeltype_label }})
							</b-form-checkbox>
						</p>
					</div>
				</template>
			</div>

			<div v-if="ca_actes_models.length > 0" class="box">
				<h3>{{ $t('tiers.parametres.association_modele_acte') }}</h3>
				<template>
					<p
						v-for="model in ca_actes_models"
						:key="`acte_model_${model.model_id}`"
					>
						<b-form-checkbox
							v-model="model.default"
							:unchecked-value="false"
							:checked-value="true"
							@change="toggleActeNote('acte', model.model_id)"
						>
						{{ model.model_label }} ({{ model.type.modeltype_label }})
						</b-form-checkbox>
					</p>
				</template>
			</div>

			<div v-if="ca_notes_models.length > 0" class="box">
				<h3>{{ $t('tiers.parametres.association_modele_note') }}</h3>
				<template>
					<p
						v-for="model in ca_notes_models"
						:key="`note_model_${model.model_id}`"
					>
						<b-form-checkbox
							v-model="model.default"
							:unchecked-value="false"
							:checked-value="true"
							@change="toggleActeNote('note', model.model_id)"
						>
						{{ model.model_label }} ({{ model.type.modeltype_label }})
						</b-form-checkbox>
					</p>
				</template>
			</div>

			<div class="box">
				<h3>{{ $t('tiers.parametres.configuration_duedate_config') }}</h3>

				<template>
					<b-form-radio-group v-model="duedate_config_selected">
						<div v-for="duedate_config in duedate_configs" :key="duedate_config.duedateconfig_id">
							<b-form-radio
								:value="duedate_config.duedateconfig_id"
							>
							{{ $t(duedate_config.duedateconfig_label) }}
							</b-form-radio>

						</div>
					</b-form-radio-group>
				</template>
			</div>

			<div class="box">
				<h3>{{ $t('tiers.parametres.methode_paiement_privilegiee') }}</h3>

				<template>
					<b-form-radio-group v-model="payment_method_selected">
						<div v-for="payment_method in payment_methods" :key="payment_method.paymentmethod_id">
							<b-form-radio
								:value="payment_method.paymentmethod_id"
							>
							{{ $t(payment_method.paymentmethod_label) }}
							</b-form-radio>

						</div>
					</b-form-radio-group>
				</template>

				<div class="mt-3" v-if="gocardless">
					<label>ID Gocardless</label>
					<div v-if="gocardless_loading"><font-awesome-icon :icon="['fas', 'spinner']" pulse /></div>
					<input v-else type="text" class="form-control" v-model="gocardless_id">
				</div>
			</div>
		</template>
		<div v-else>
			<LoadingSpinner class="col-12" />
		</div>

		<div class="text-center" v-if="saveButton">
			<button class="btn btn-secondary btn-rounded mr-2" @click.prevent="resetForm">{{ $t('global.annuler') }} <font-awesome-icon :icon="['fal', 'times']"/></button>
			<button class="btn btn-primary btn-rounded" @click.prevent="submitForm">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']"/></button>
		</div>
	</div>
</template>

<script type="text/javascript">
import Tools from '@/mixins/Tools.js'
import Phone from "@/mixins/Phone.js"
import Tiers from "@/mixins/Tiers.js"
import Accounting from "@/mixins/Accounting.js"
import Model from "@/mixins/Model.js"

export default {
	name: 'coordonneesTiers',
	mixins: [Tools, Phone, Tiers, Accounting, Model],
	props: {
		tiers: { type: Object, default: () => ({}) },
	},
	data() {
		return {
			models_save: null,
			duedate_config_selected_save: null,
			saveButton: false,
			saveButtonModel: false,
			saveButtonConfigDuedate: false,
			saveButtonPaymentMethod: false,
			saveButtonGocardless: false,
			loaded: false,
			processing: false,
			duedate_config_selected: null,
			accounting_plans: [],
			duedate_configs: [],
			ca_actes_models: [],
			ca_notes_models: [],
			payment_methods: [],
			payment_method_selected: null,
			payment_method_selected_save: null,
			gocardless_id: null,
			gocardless_id_save: null,
			gocardless: false,
			gocardless_loading: false
		}
	},
	computed: {
		hasClientArea() {
			return this.$store.state.userAccess.hasDedicatedClientArea
		}
	},

	/* Initialisation du tableau */
	mounted: function() {
		this.initComponent()
	},

	methods: {
		async initComponent() {
			if(this.tiers.tiers_id != undefined) {
				this.duedate_configs = await this.loadDuedateConfig()

				for (let i = 0; i < this.duedate_configs.length; i++) {
					if(this.tiers.tiers_duedateconfig == this.duedate_configs[i].duedateconfig_id || (i == 0 && this.tiers.tiers_duedateconfig == null)) {
						this.duedate_config_selected = this.duedate_configs[i].duedateconfig_id
					}
				}

				this.duedate_config_selected_save = this.duedate_config_selected
				this.accounting_plans = await this.loadAccountingPlans()

				const temp = await this.loadTiersModels(this.tiers.tiers_id)
				const models_tiers = temp.models || []

				// Modèles de notifs facture
				for (let i = 0; i < this.accounting_plans.length; i++) {
					let models = await this.loadAllModel(this.accounting_plans[i].accountingplan_id, ['accounting_plan'])
					this.tiers.tiers_id = await this.replaceOrSynchronizeId(this.tiers.tiers_id)

					for (let j = 0; j < models.length; j++) {
						const checked = models_tiers.filter(model => model.type.modeltype_relation == 'accounting_plan' && model.model_id == models[j].model_id)[0]

						if(checked != undefined) {
							models[j].default = true
						}
						else {
							models[j].default = false
						}
					}

					this.$set(this.accounting_plans[i], 'models', models)
				}

				// Modèles de notifs actes / notes (espace client)
				if(this.hasClientArea) {
					const modelable_id  = await this.getAsyncConfig('licence')
					const ca_models = await this.loadAllModel(modelable_id, ['licence'])

					ca_models.forEach(model => {
						const checked = models_tiers.find(m => m.model_id == model.model_id)
						model.default = !!checked
					})

					this.ca_actes_models = ca_models.filter(m => m.type.modeltype_template == 'notif_client_area')
					this.ca_notes_models = ca_models.filter(m => m.type.modeltype_template == 'notes_notify_ca')
				}

                this.payment_methods = this.getConfig('payment_method')
				for (let i = 0; i < this.payment_methods.length; i++) {
					if(this.tiers.tiers_paymentmethod == this.payment_methods[i].paymentmethod_id) {
						this.payment_method_selected = this.payment_methods[i].paymentmethod_id
					}
					this.payment_methods[i].paymentmethod_label = this.getTrad('payment.payment_method_labels.' + this.payment_methods[i].paymentmethod_label.replaceAll(' ', '_').toLowerCase())
				}

				this.payment_method_selected_save = this.payment_method_selected

				this.models_save = this.deppCloneObj([
					this.accounting_plans, 
					this.ca_actes_models, 
					this.ca_notes_models
				])
				this.loaded = true
			}
		},
		toggle(accountingplan_id, model_id) {
			let current_accounting_plan = this.accounting_plans.filter(acc => acc.accountingplan_id == accountingplan_id)[0]

			if(current_accounting_plan.models.length > 0) {
				for(let i = 0; i < current_accounting_plan.models.length; i++) {

					if(current_accounting_plan.models[i].model_id != model_id) {
						current_accounting_plan.models[i].default = false
					}
				}
			}
		},
		toggleActeNote(type, model_id) {
			let to_edit = []

			if(type == 'acte') {
				to_edit = this.ca_actes_models
			}
			else {
				to_edit = this.ca_notes_models
			}

			const actual_selected = to_edit.find(m => m.default === true)
			if(!actual_selected || actual_selected.model_id != model_id) {
				this.saveButtonModel = true
			}

			for(let i = 0; i < to_edit.length; i++) {
				to_edit[i].default = false

				if(to_edit[i].model_id == model_id) {
					to_edit[i].default = true
				}
			}
		},
		toggleDuedateConfig(duedateconfig_id) {
			for (let i = 0; i < this.duedate_configs.length; i++) {
				if(this.duedate_configs[i].duedateconfig_id != duedateconfig_id) {
					this.duedate_configs[i].default = false
				}
			}
		},
		resetForm() {
			this.accounting_plans = this.deppCloneObj(this.models_save[0])
			this.ca_actes_models = this.deppCloneObj(this.models_save[1])
			this.ca_notes_models = this.deppCloneObj(this.models_save[2])

			this.duedate_config_selected = this.deppCloneObj(this.duedate_config_selected_save)
			this.payment_method_selected = this.deppCloneObj(this.payment_method_selected_save)
		},
		async checkForm() {
			let params = {
				models: []
			}

			for (let i = 0; i < this.accounting_plans.length; i++) {
				if(this.accounting_plans[i].models.length > 0) {
					for (let j = 0; j < this.accounting_plans[i].models.length; j++) {
						if(this.accounting_plans[i].models[j].default) {
							const acc_id = { model_id: this.accounting_plans[i].models[j].model_id, accountingplan_id: this.accounting_plans[i].accountingplan_id }
							params.models.push(acc_id)
						}
					}
				}
			}

			const default_acte_model = this.ca_actes_models.find(m => m.default === true)
			const default_note_model = this.ca_notes_models.find(m => m.default === true)
			if(default_acte_model) params.models.push({ model_id: default_acte_model.model_id, accountingplan_id: null })
			if(default_note_model) params.models.push({ model_id: default_note_model.model_id, accountingplan_id: null })

			let result = await this.saveParametersTiers(this.tiers.tiers_id, params)

			if(result) {   
				this.saveButtonModel = false
				return true
			}
			else {
				return false
			}
		},
		async checkFormDuedateConfig() {
			let result = null

			this.tiers.tiers_duedateconfig = this.duedate_config_selected
			result = await this.saveDuedateConfigTiers(this.tiers.tiers_id, this.duedate_config_selected)

			if(result) {   
				this.duedate_config_selected_save = this.duedate_config_selected
				this.saveButtonConfigDuedate = false
				return true
			}
			else {
				return false
			}
		},
		async checkFormPaymentMethod() {
			let result = null

			this.tiers.tiers_paymentmethod = this.payment_method_selected
			result = await this.savePaymentMethodTiers(this.tiers.tiers_id, this.payment_method_selected)

			if(result) {   
				this.payment_method_selected_save = this.payment_method_selected
				this.saveButtonPaymentMethod = false
				return true
			}
			else {
				return false
			}
		},
		async checkFormGocardless() {
			let result = null

			this.tiers.tiers_paymentmethod = this.payment_method_selected
			result = await this.saveGocardlessTiers(this.tiers.tiers_id, this.gocardless_id)

			if(result) {   
				this.gocardless_id_save = this.gocardless_id
				this.saveButtonGocardless = false
				return true
			}
			else {
				return false
			}
		},
		showSaveButton() {
			if(this.saveButtonModel || this.saveButtonConfigDuedate || this.saveButtonPaymentMethod || this.saveButtonGocardless) {
				this.saveButton = true
			}
			else {
				this.saveButton = false
			}
		},
		async submitForm() {
			if(!this.processing) {
				let success = true
				this.processing = true
				if(this.saveButtonModel) {
					if(!await this.checkForm()) {
						success = false
					}
				}

				if(this.saveButtonConfigDuedate) {
					if(!await this.checkFormDuedateConfig()) {
						success = false
					}
				}

				if(this.saveButtonPaymentMethod) {
					if(!await this.checkFormPaymentMethod()) {
						success = false
					}
				}

				if(this.saveButtonGocardless) {
					if(!await this.checkFormGocardless()) {
						success = false
					}
				}

				this.processing = false

				if(success) {
					this.successToast()
				}
				else {
					this.failureToast()
				}
			}
		},
		async initGocardless() {
			this.gocardless_loading = true
			this.gocardless_id = null

			const val = this.payment_method_selected
			const result = this.payment_methods.filter(function(v, i) {
				return v.paymentmethod_id === val;
			});

			if(result.length > 0 && result[0].paymentmethod_code == 'GCL') {
				this.gocardless = true
				const gocardless = await this.getGocardlessUrl(this.tiers.tiers_id)
				if(gocardless && gocardless.gocardlessid) {
					this.gocardless_id = gocardless.gocardlessid
					this.gocardless_id_save = gocardless.gocardlessid
				}
			} else {
				this.gocardless = false
			}

			this.gocardless_loading = false
		}
	},
	watch: {
		accounting_plans: {
			handler(val) {
				if(!this.models_save || !this.loaded) return

				if(JSON.stringify(val) != JSON.stringify(this.models_save[0])) {
					this.saveButtonModel = true
				}
				else {
					this.saveButtonModel = false
				}
			},
			deep: true
		},
		duedate_config_selected:{
			handler(val) {
				if(this.duedate_config_selected_save != val && this.loaded) {
					this.saveButtonConfigDuedate = true
				}
				else {
					this.saveButtonConfigDuedate = false
				}
			},
			deep: true
		},
		payment_method_selected:{
			handler(val) {
				if(this.payment_method_selected_save != val && this.loaded) {
					this.saveButtonPaymentMethod = true
				}
				else {
					this.saveButtonPaymentMethod = false
				}

				this.initGocardless()
			},
			deep: true
		},
		gocardless_id:{
			handler(val) {
				if(this.gocardless_id_save != val && this.loaded) {
					this.saveButtonGocardless = true
				}
				else {
					this.saveButtonGocardless = false
				}
			},
			deep: true
		},
		saveButtonModel:{
			handler(val) {
				this.showSaveButton()
			},
			deep: true
		},
		saveButtonConfigDuedate:{
			handler(val) {
				this.showSaveButton()
			},
			deep: true
		},
		saveButtonPaymentMethod:{
			handler(val) {
				this.showSaveButton()
			},
			deep: true
		},
		saveButtonGocardless:{
			handler(val) {
				this.showSaveButton()
			},
			deep: true
		}
	},
	components: {
		LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
	}
}
</script>
